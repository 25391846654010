import React from 'react';

import { LocaleContext } from '../context';

const LocaleProvider = ({
  children,
  pageContext: { locale, defaultLocale, translations },
}) => {
  return (
    <LocaleContext.Provider value={{ locale, defaultLocale, translations }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
