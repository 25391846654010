module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"füsioterapeut.ee","short_name":"füsioterapeut.ee","start_url":"/?utm_source=standalone","background_color":"#ffffff","theme_color":"#00b8d8","display":"standalone","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/favicons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0034535494d73403a3610c4f86a4627a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WXC5HP4","routeChangeEventName":"page_view","includeInDevelopment":false,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
