exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-diagnoses-js": () => import("./../../../src/templates/Diagnoses.js" /* webpackChunkName: "component---src-templates-diagnoses-js" */),
  "component---src-templates-general-terms-of-service-js": () => import("./../../../src/templates/GeneralTermsOfService.js" /* webpackChunkName: "component---src-templates-general-terms-of-service-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-registration-action-js": () => import("./../../../src/templates/RegistrationAction.js" /* webpackChunkName: "component---src-templates-registration-action-js" */),
  "component---src-templates-registration-default-js": () => import("./../../../src/templates/RegistrationDefault.js" /* webpackChunkName: "component---src-templates-registration-default-js" */),
  "component---src-templates-registration-js": () => import("./../../../src/templates/Registration.js" /* webpackChunkName: "component---src-templates-registration-js" */),
  "component---src-templates-registration-tk-js": () => import("./../../../src/templates/RegistrationTK.js" /* webpackChunkName: "component---src-templates-registration-tk-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/Services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

